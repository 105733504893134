import React, { useState, useEffect } from "react";
import API from "../utils/api";
import Loader from "../components/Loader/Loader";
import { Link } from "react-router-dom";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contactusValidation from "../validation/contactusValidation";
import moment from "moment";
import { delete_cookie, readCookie } from "../cookie-function";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HomePage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [configPage, setConfigPage] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [paginationObj, setPaginationObj] = useState({});
  const [emptyMessage, setEmptyMessage] = useState("");
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(3);

  const [expanded, setExpanded] = useState(false);
  const [activeClass, setActiveClass] = useState("");

  const [itemsToShow1, setItemsToShow1] = useState(4);
  const [expanded1, setExpanded1] = useState(false);
  const [categoryDetailHome, setCategoryDetailHome] = useState([]);
  const [emptyMessageHome, setEmptyMessageHome] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [categoryName, setCategoryName] = useState([]);
  const [categoryValue, setCategoryValue] = useState('');

  const [errors, setErrors] = useState({});

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   getFeaturedArticle();
  //   getCategoryArticleList();
  //   categoryListData();
  //   API.get("config/1")
  //     .then(({ data }) => {
  //       setConfigPage(data.data.home_page_links);
  //     })
  //     .catch((err) => {});
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchCategoryArticleList = getCategoryArticleList();
    const fetchFeaturedArticle = getFeaturedArticle();
    const fetchCategoryListData = categoryListData();
    const fetchConfigPage = API.get("config/1").then(({ data }) => {
      setConfigPage(data.data.home_page_links);
    });

    setIsLoading(true);
    Promise.all([fetchFeaturedArticle, fetchCategoryArticleList, fetchCategoryListData, fetchConfigPage])
      .catch((err) => {
        // Handle errors if any of the promises fail
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    ;
  }, []);

  const categoryChangeHandler = (e) => {
    setCategoryValue(e?.target?.value);
  };
  // CONTACT US API
  const contactUsApiCalled = (e) => {
    e.preventDefault();
    const { errors, isValid } = contactusValidation({
      name,
      email,
      message,
      subject,
      phone,
      categoryValue,
    });
    setErrors(errors);
    if (isValid) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("message", message);
      formData.append("type", 'contact');
      formData.append("feedback", 'contact');
      if (categoryValue.length > 0) {
        formData.append("category_id", categoryValue);
      }
      if (phone.length > 0) {
        formData.append("phone", phone);
      }
      API.post("contact-us", formData)
        .then((res) => {
          if (res.data.meta.code === 1) {
            toast.success(res.data.meta.message, {
              onClose: () => {
                setName("");
                setEmail("");
                setSubject("");
                setPhone("");
                setMessage("");
                setCategoryValue([]);
              },
            });
          }
          if (res.data.meta.code === 0) {
            toast.error(res.data.meta.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      ;
    }
  };

  // FEATURED ARTICLES
  const getFeaturedArticle = () => {
    const formData = new FormData();
    formData.append("search", "");
    formData.append("page", "1");
    // formData.append("post_per_page", "10"); TO REDUCE LOADING TIME
    formData.append("post_per_page", itemsToShow);
    formData.append("is_featured", 1);

    return API.post("article-list", formData)
      .then((res) => {
        if (res.data.meta.code === 1) {
          setCategoryDetail(res.data.data);
          setPaginationObj({
            total: res.data.meta.total,
            page: parseInt(res.data.meta.page),
            perPage: parseInt(res.data.meta.perPage),
          });
          setEmptyMessage(res.data.meta.message);
        }
      })
      .catch((err) => {
        console.error(err);
        // Optionally handle the error, e.g., display a toast notification
      });
  };

  // NON - FEATURED ARTICLES 
  const getCategoryArticleList = () => {
    const formData = new FormData();
    formData.append("search", "");
    formData.append("page", "1");
    // formData.append("post_per_page", "12");// TO REDUCE LOADER TIME
    formData.append("post_per_page", "8");
    formData.append("is_featured", "0");

    return API.post("article-list", formData)
      .then((res) => {
        let arr = [],
          i = 0,
          inner = [];
        let sliceArr = res.data.data.slice(0, 18);
        let rloop = sliceArr.length % 1;
        for (const iterator of sliceArr) {
          inner.push(iterator);
          ++i;
          if (i == 1) {
            i = 0;
            arr.push(JSON.parse(JSON.stringify(inner)));
            inner = [];
          }
        }

        if (rloop != 0) {
          arr.push(sliceArr.slice(-rloop));
        }
        setCategoryDetailHome(arr);
        setEmptyMessageHome(res.data.meta.message);
      })
      .catch((err) => {
        console.error(err);
        // Optionally handle the error
      });
  };

  // CATEGORY LIST
  const categoryListData = () => {
    return API.get("categories/list")
      .then(({ data }) => {
        if (data.meta.code === 1) {
          setCategoryList(data.data);
          setCategoryName(data.data);
          if (readCookie("SelectedId")) {
            setActiveClass(readCookie("SelectedId"));
          }
        }
      })
      .catch((err) => {
        console.error(err);
        // Optionally handle the error
      });
  };

  const showMore = () => {
    if (itemsToShow1 === 4) {
      setItemsToShow1(categoryList.length);
      setExpanded1(true);
    } else {
      setItemsToShow1(4);
      setExpanded1(false);
    }
  };
  const showActiveTab = (id) => {
    document.cookie = "SelectedId=" + id;
    setActiveClass(id);
  };

  // SLIDER OPTIONS
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: true,
    loop: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      400: {
        items: 1,
        nav: false,
      },
      600: {
        items: 2,
        nav: false,
      },
      700: {
        items: 3,
        nav: false,
      },
      1000: {
        items: 3,
        nav: false,
      },
      1366: {
        items: 3,

      }
    },
  };
  return (
    <div className="position-relative">
      {isLoading && <Loader />}

      <section className="home-new-section1">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <h1 className="fw-bolder max-width-511 fs-40">
                freeD - A <span className="text-primary">Sea-change</span> in Perception
              </h1>
              <p className="fs-20 fw-bold mb-10">That Can Create Enduring Overall Wellbeing</p>
              {/* <p>
              Advancements in science and technology have significantly altered how humans interact with and access the ocean.
              </p> */}
              <Link
                className="text-deco"
                to={{
                  pathname: `/detail-page/${configPage.home_page_link_1}`,
                  state: {
                    home_page_link: configPage.home_page_link_1,
                  },
                }}>
                <button className="btn btn-secondary text-uppercase">
                  <span style={{ color: "white", marginRight: "5px" }}>
                    Learm More
                  </span>
                  <span>
                    <img src="assets/images/white-arrow.svg" alt="white arrow icon" />
                  </span>
                </button>
              </Link>
            </div>
            <div className="col-md-6 text-right">
              <img className="img-fluid" src="assets/images/home-page-new-banner.png"></img>
            </div>
          </div>
        </div>
      </section>
      <section className="home-new-seciton2">
        <div className="container">
          <div className="row align-items-center home-mobile-center">
            <div className="col-md-6">
              <div className="home-new-seciton2-left">
                <img src="assets/images/hikers-top.png" alt="Nutshell image" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-new-seciton2-left-right">
                <h2 className="fw-bold fs-45"><span className="fw-bold">freeD In A  Nutshell</span></h2>

                <p className="fs-18 fw-normal mb-3 font-fam-pop">
                  Thinking, acting, and leading that enhances ethical autonomy
                  with personal responsibility to achieve “overall wellbeing”
                  throughout the world.
                </p>
                <Link
                  className="text-deco"
                  to={{
                    pathname: `/detail-page/${configPage.home_page_link_2}`,
                    state: {
                      home_page_link: configPage.home_page_link_2,
                    },
                  }}>
                  <button className="btn btn-secondary text-uppercase">
                    <span style={{ color: "white", marginRight: "5px" }}>
                      Learn More
                    </span>
                    <span>
                      <img src="assets/images/white-arrow.svg" alt="white arrow image" />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-new-seciton3 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="fw-normal fs-45"><span className="fw-bold">Examining your starting point </span></h2>
              <p className="mb-50 fs-35 font-fam-pop">
                is a first step in markedly reshaping your perception
              </p>
            </div>
          </div>
          <div className="row mobile-text-center mw-1016">
            <div className="col-md-6 p-23">
              <div className="home-new-seciton3-img">
                <Link
                  to={{
                    pathname: `/detail-page/${configPage.home_page_link_3}`,
                    state: {
                      home_page_link: configPage.home_page_link_3,
                    },
                  }}>
                  <img src="assets/images/front-view-female-tourist-holding-earth-globe.png" />
                  <div className="w-100 fw-bold fs-24 bottom-0   mobile-fs-17 position-absolute text-center text-uppercase text-white pb-2">
                    I AM A PART OF <br />THE WORLD
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 p-23">
              <div className="home-new-seciton3-img">
                <Link
                  to={{
                    pathname: `/detail-page/${configPage.home_page_link_4}`,
                    state: {
                      home_page_link: configPage.home_page_link_4,
                    },
                  }}>
                  <img src="assets/images/home-section3-right.png" alt="apart form image" />
                  <div className="w-100 fw-bold fs-24 mobile-fs-17  bottom-0 pb-2 position-absolute text-center text-uppercase text-default">
                    I AM APART FROM <br />THE WORLD
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-new-seciton4">
        <div className="container">

          <div className="row ">
            <div className="col-md-12 text-center">
              <h2 className="fs-45 text-primary fw-bold">Articles</h2>
              {/* <p className="fs-25 mb-0">Neque porro quisquam est qui dolorem ipsum quia dolor...</p> */}
            </div>
            <div className="col-md-12">
              <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist">
                {categoryList.slice(0, itemsToShow1).map((item, index) => (
                  <Link
                    key={index + 1}
                    to={{
                      pathname: "/category-detail",
                      state: {
                        category_name: item.name,
                        category_id: item.id,
                        category_seo_url: item.seo_url,
                        page_path: "home_page",
                      },
                    }}>
                    <li
                      className="nav-item category-list-view"
                      role="presentation"
                      key={item.id}
                      onClick={() => showActiveTab(item.id)}>
                      <button
                        // className="nav-link"
                        className={`nav-link ${activeClass == item.id ? "active" : ""
                          }`}
                        id="home-tab"
                        data-toggle="tab"
                        data-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true">
                        {item.name}
                      </button>
                    </li>
                  </Link>
                ))}
                <p>
                  <a
                    className="btn btn-primary home-page-show-button"
                    onClick={showMore}>
                    {expanded1 ? (
                      <span>View Less </span>
                    ) : (
                      <span>View More </span>
                    )}
                  </a>
                </p>
              </ul>
              <div className="tab-content" id="myTabContent">

                <OwlCarousel className='owl-theme home-slider' {...options} >
                  {categoryDetailHome.map((item, i) => (

                    <div key={i + 1} className="">
                      {item && item?.length > 0 ? (
                        item?.map((item, i) => (
                          <div
                            key={i + 1}
                            className="item">

                            <div className="">
                              <Link className="text-decoration-none"
                                to={{
                                  pathname: `/detail-page/${item.seo_url}`,
                                  state: {
                                    blog_seo_url: item.seo_url,
                                  },
                                }}>
                                <div className="category-detail-image-cover">
                                  <div className="image-cover">
                                    <img src={item.image_thumb} alt="thumb image" />
                                  </div>
                                  <div className="p-3">
                                    <span className="category-detail-box-sub">{item.author_name}</span>
                                    <div className="fw-bold fs-26 mt-2 text-default">{item.name}</div>
                                    {/* <p
                                        className="sec-2-box-sub-2"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></p> */}
                                    <div className="fs-16 mt-1 fw-semibold color-br">
                                      {moment(
                                        new Date(item.created_at)
                                      ).format("MMM DD, YYYY")}
                                    </div>

                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p
                          style={{
                            fontWeight: "bold",
                            marginLeft: "12px",
                          }}>
                          {emptyMessageHome}
                        </p>
                      )}

                    </div>
                  ))}
                </OwlCarousel>
                <div className="text-center mt-3">

                  <Link
                    className="text-deco d-inline-block"
                    to={{
                      pathname: "/category-detail",
                      state: {
                        view_all: "corAart",
                        title_name: "Articles",
                        page_path: "home_page",
                      },
                    }}>
                    <button className="btn btn-secondary text-uppercase">
                      <span className="me-2 text-white">
                        View More
                      </span>
                      <span>
                        <img src="assets/images/white-arrow.svg" alt="white arrow image" />
                      </span>
                    </button>
                  </Link>
                </div>



              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-new-seciton4 home-feature-section">
        <div className="container position-relative z-index-2">
          <div className="row align-items-end">
            <div className="col-md-12 d-flex">
              <div className="home-new-seciton4-left">
                <h2 className="text-white fs-45 fw-bold">Featured Articles</h2>
                <p className="mb-0 fs-25 text-white">
                  Featured articles are considered to be some of the best
                  articles...
                </p>
              </div>
              <div className="home-new-seciton4-right">
                <Link
                  to={{
                    pathname: "/category-detail",
                    state: {
                      view_all: "clicked",
                      title_name: "Featured Articles",
                      page_path: "home_page",
                    },
                  }}
                  className="btn btn-white fw-bold text-uppercase">
                  View More
                  <span className="ms-2">
                    <img src="assets/images/blue-arrow.svg" alt="blue arrow image" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row home-new-seciton5-box-cover">
            {categoryDetail.slice(0, itemsToShow).map((item, index) => (
              <div className="col-md-4" key={index + 1}>
                <Link
                  className="text-deco"
                  to={{
                    pathname: `/detail-page/${item.seo_url}`,
                    state: {
                      blog_seo_url: item.seo_url,
                    },
                  }}>
                  <div className="category-detail-image-cover feature-box">
                    <div className="image-cover">
                      <img src={item.image_thumb} alt="thumb image" />
                    </div>
                    <div className="p-3 feature-box-content">
                      <h4 className="fs-16 fw-bold mt-2 text-primary">{item.author_name}</h4>
                      <h3 className="fs-25 fw-bold mt-2 text-default">{item.name}</h3>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <section className="home-new-seciton6">
        <div className="container">
          <div className="row align-items-center text-align-terms">
            <div className="col-md-7 col-lg-8">
              <div className="home-new-seciton6-media">
                <div className="home-new-seciton6-media-img">
                  <img src="assets/images/peter.png" />
                </div>
                <div className="home-new-seciton6-media-text-cover">
                  <div className="home-new-seciton6-media-title">
                    Horst Lehrheuer
                  </div>
                  <div className="home-new-seciton6-media-text">
                    Creator of the freeD framework
                  </div>
                </div>
              </div>
              <h2>How did freeD transpire? – </h2>
              <h3>A brief, primarily rear-view-mirror perspective</h3>
              <p>
                freeDPerception is the creation of Horst Lehrheuer. That would
                not have been possible without standing on the shoulders of so
                many “giants” in their respective fields – among them, in
                particular,{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Peter_Drucker"
                  target="_blank"
                >
                  Peter Drucker
                </a>{" "}
                (mainly during Horst’s professional career) and{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Heinz_von_Foerster"
                  target="_blank"
                >
                  Heinz von Foerster
                </a>{" "}
                (increasingly so from the early 2000s).
              </p>
              <div className="home-new-seciton6-btn-cover">
                <Link
                  className="btn btn-secondary"
                  to={{
                    pathname: `/detail-page/${configPage.home_page_link_5}`,
                    state: {
                      home_page_link: configPage.home_page_link_5,
                    },
                  }}
                >
                  READ FULL ARTICLE{" "}
                  <span>
                    <img src="assets/images/white-arrow.svg" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="home-new-seciton6-right-first-cover">
                <div className="home-new-seciton6-right-first">
                  <div className="home-new-seciton6-right-img">
                    <img src="assets/images/Heinz-Foerster.png" />
                  </div>
                  <div className="home-new-seciton6-right-username">
                    Heinz Foerster
                  </div>
                </div>
                <div className="home-new-seciton6-right-first">
                  <div className="home-new-seciton6-right-img">
                    <img src="assets/images/Peter-Drucker.png" />
                  </div>
                  <div className="home-new-seciton6-right-username">
                    Peter Drucker
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    */}
      <section className="home-new-seciton7">
        <div className="container position-relative">
          <div className="home-contact-box">

            <div className="row mb-3 align-items-center">
              <div className="col-xl-3 col-md-12 mb-2">
                <div className="fw-bold fs-33 mb-2">GET IN TOUCH</div>
                <h2 className="fw-bold fs-20 text-primary">Write us a message</h2>
              </div>
              <div className="col-md-12 col-xl-9">
                <div className="row g-0">
                  <div className="col-md-4">
                    <a
                      href="mailto:horst@freedperception.com"
                      className="anchor-text black-text">
                      <div className="d-flex mobile-gap-10">
                        <div className="home-new-seciton7-box-img">
                          <img src="assets/images/email-icon.svg" alt="email image" />
                        </div>
                        <div className="home-new-seciton7-box-text">
                          <h4 className="fs-25 fw-bold mb-1">EMAIL</h4>
                          <p className="fs-16 mb-0">horst@freedperception.com</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex mobile-gap-10">
                      <div className="home-new-seciton7-box-img">
                        <img src="assets/images/office-building.svg" alt="office building image" />
                      </div>
                      <div className="home-new-seciton7-box-text">
                        <h4 className="fs-25 fw-bold mb-1">OFFICE ADDRESS</h4>
                        <p className="fs-16 mb-0">Mountain View, CA 94043 - USA</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex">
                      <div className="home-new-seciton7-box-img">
                        <img src="assets/images/Phone.svg" alt="phone image" />
                      </div>
                      <div className="home-new-seciton7-box-text">
                        <h4 className="fs-25 fw-bold mb-1">PHONE</h4>
                        <p className="fs-16 mb-0">Please email us to request a callback </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-md-7">

                <form>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label>
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          className={
                            errors.name
                              ? "form-control is-invalid border-radius-0"
                              : "form-control border-radius-0"
                          }
                          onFocus={() => {
                            errors.name = false;
                          }}
                          id="name"
                          placeholder="Enter your name"
                          name="name"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />

                        {errors.name ? (
                          <div className="error-feedback">{errors.name}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          className={
                            errors.email
                              ? "form-control is-invalid border-radius-0"
                              : "form-control border-radius-0"
                          }
                          onFocus={() => {
                            errors.email = false;
                          }}
                          placeholder="Enter your email"
                          name="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email ? (
                          <div className="error-feedback">{errors.email}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Subject <span className="text-danger">*</span>
                        </label>
                        <input
                          className={
                            errors.subject
                              ? "form-control is-invalid border-radius-0"
                              : "form-control border-radius-0"
                          }
                          onFocus={() => {
                            errors.subject = false;
                          }}
                          placeholder="Enter the subject"
                          name="subject"
                          type="text"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        {errors.subject ? (
                          <div className="error-feedback">{errors.subject}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          className={
                            errors.phone
                              ? "form-control is-invalid border-radius-0"
                              : "form-control border-radius-0"
                          }
                          onFocus={() => {
                            errors.phone = false;
                          }}
                          placeholder="Enter phone number"
                          name="phone"
                          type="number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        {errors.phone ? (
                          <div className="error-feedback">{errors.phone}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label>
                          Category <span className="text-danger">*</span>
                        </label>
                        <select
                          value={categoryValue}
                          onChange={categoryChangeHandler}
                          className={
                            errors.categoryValue
                              ? "form-control is-invalid border-radius-0 cursor-pointer contact-select-arrow"
                              : "form-control border-radius-0 cursor-pointer contact-select-arrow"
                          }
                          onFocus={() => {
                            errors.categoryValue = false;
                          }}>
                          <option>Select</option>
                          {categoryName.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        {errors.categoryValue ? (
                          <div className="error-feedback">
                            {errors.categoryValue}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Message <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className={
                            errors.message
                              ? "form-control is-invalid border-radius-0"
                              : "form-control border-radius-0"
                          }
                          onFocus={() => {
                            errors.message = false;
                          }}
                          id="message"
                          placeholder="Enter message"
                          name="message"
                          cols="50"
                          rows="3"
                          maxLength={200}
                          value={message}
                          onChange={(e) =>
                            setMessage(e.target.value)
                          }></textarea>
                        {errors.message ? (
                          <div className="error-feedback">{errors.message}</div>
                        ) : (
                          ""
                        )}
                        {/*<p>{message.length}/200</p>*/}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-secondary text-uppercase"
                        onClick={contactUsApiCalled}>
                        Send Message
                        <span className="ms-2">
                          <img src="assets/images/white-arrow.svg" alt="white arrow image" />
                        </span>
                      </button>
                    </div>
                  </div>
                </form>

              </div>
              <div className="col-md-5">
                <div className="pe-2 ps-2 text-center mt-4"><img src="assets/images/contact-right-img.png" className="img-fluid" alt="contact image" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="home-side-icon1"></div>
      <div className="home-side-icon2"></div>
      <div className="home-side-icon3"></div>
      <div className="home-side-icon4"></div>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer /> */}
    </div>
  );
};
export default HomePage;
