import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import HomePage from "./pages/home-page";

const AboutUs = lazy(() => import("./pages/aboutUs"));
const PublishAnArticle = lazy(() => import("./pages/publishAnArticle"));
const FreeDFramework = lazy(() => import("./pages/freeD-framework"));
const AllReferences = lazy(() => import("./pages/all-references"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const Glossary = lazy(() => import("./pages/glossary"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy"));
const TermCondition = lazy(() => import("./pages/terms-conditions"));
const EditProfile = lazy(() => import("./pages/edit-profile"));
const DetailPage = lazy(() => import("./pages/detail-page"));
const ExternalVideos = lazy(() => import("./pages/external-videos"));
const CategoryDetail = lazy(() => import("./pages/category-detail-page"));
const MyArticlePage = lazy(() => import("./pages/my-article-page"));
const RegisterPage = lazy(() => import("./pages/register"));
const LoginPage = lazy(() => import("./pages/login"));
const ValidateOtp = lazy(() => import("./pages/validateOtp"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ResetPassword = lazy(() => import("./pages/reset-password"));

const NoPageFound = lazy(() => import("./pages/nopagefound"));

const routes = [
  {
    name: "HomePage",
    component: HomePage,
    exact: true,
    path: "/",
  },
  {
    name: "ExternalVideos",
    component: ExternalVideos,
    exact: true,
    path: "/external-videos",
  },
  {
    name: "ForgotPassword",
    component: ForgotPassword,
    exact: true,
    path: "/forgot-password",
  },
  {
    name: "ResetPassword",
    component: ResetPassword,
    exact: true,
    path: "/reset-password/:token",
  },
  {
    name: "ValidateOtp",
    component: ValidateOtp,
    exact: true,
    path: "/validate-otp",
  },
  {
    name: "Register",
    component: RegisterPage,
    exact: true,
    path: "/register",
  },
  {
    name: "Login",
    component: LoginPage,
    exact: true,
    path: "/login",
  },
  {
    name: "CategoryDetail",
    component: CategoryDetail,
    exact: true,
    path: "/category-detail",
  },
  {
    name: "FreeDFramework",
    component: FreeDFramework,
    exact: true,
    path: "/freeD-framework",
  },
  // {
  //   name: "PublishAnArticle",
  //   component: PublishAnArticle,
  //   exact: true,
  //   path: "/publish-an-article",
  // },
  {
    name: "TermsCondition",
    component: TermCondition,
    exact: true,
    path: "/terms-condition",
  },
  {
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    exact: true,
    path: "/privacy-policy",
  },

  {
    name: "AboutUs",
    component: AboutUs,
    exact: true,
    path: "/about-us",
  },

  {
    name: "ContactUs",
    component: ContactUs,
    exact: true,
    path: "/contact-us",
  },

  {
    name: "AllReferences",
    component: AllReferences,
    exact: true,
    path: "/all-references",
  },
  {
    name: "Glossary",
    component: Glossary,
    exact: true,
    path: "/glossary",
  },
  {
    name: "Edit-Profile",
    component: EditProfile,
    exact: true,
    path: "/edit-profile",
  },
  {
    name: "My-Article",
    component: MyArticlePage,
    exact: true,
    path: "/my-article",
  },
  {
    name: "Detail-Page",
    component: DetailPage,
    exact: true,
    path: "/detail-page/:id?",
  },
  {
    name: "No-Page-Found",
    component: NoPageFound,
    exact: true,
    path: "/404",
  },
  {
    name: "No-Page-Found",
    component: NoPageFound,
    exact: true,
    path: "*",
  },
];
const Routes = () => {
  return (
    <div>
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.name}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </Suspense>
    </div>
  );
};
export default Routes;
