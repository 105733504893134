import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { readCookie, delete_cookie } from "../../cookie-function";
import ReactGA from 'react-ga';


const Header = ({ statsCookie }) => {
  // var logTime = readCookie("loginToken");
  // console.log("cookie-token", logTime);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");



  useEffect(() => {
    if (statsCookie === true) {
      ReactGA.initialize('UA-300168360-1');
      ReactGA.pageview(location.pathname + location.search);
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.search });
    }

  }, [location, statsCookie]);

  const logoutHandlerCalled = (e) => {
    e.preventDefault();
    delete_cookie("loginToken");
    delete_cookie("loggedUserImage");
    delete_cookie("EditUserImage");
    delete_cookie("UserId");
    history.push("/login");
    // window.location.reload();
  };
  const removeIdLocalStorage = (e) => {
    e.preventDefault();
    delete_cookie("SelectedId");
  };
  const freedFramework = () => {
    delete_cookie("SelectedId");
  };
  return (
    <header>
      <div className="container-fluide">
        <nav className="navbar  navbar-expand-xl navbar-light">
          <Link className="navbar-brand me-0" to="/" onClick={freedFramework}>
            {splitLocation[1] === "detail-page" ||
              splitLocation[1] === "reset-password" ? (
              <img src="../assets/images/logo.svg" />
            ) : (
              <img src="assets/images/logo.svg" />
            )}

            {/* <img src="assets/images/logo.svg" /> */}
          </Link>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav header-aling ">
              {/* <li
                className={`nav-item ${
                  splitLocation[1] === "" ? "active" : ""
                }`}
              >
                <Link to="/" className="nav-link">
                  Home Page
                </Link>
              </li> */}

              <li
                className={`nav-item ${splitLocation[1] === "freeD-framework" ? "active" : ""
                  }`}
              >
                <Link
                  to="/freeD-framework"
                  className="nav-link"
                  onClick={freedFramework}
                >
                  freeD Framework
                </Link>
              </li>
              {/* PUBLISH AN ARTICLE LINK */}
              {/* <li
                className={`nav-item ${
                  splitLocation[1] === "publish-an-article" ? "active" : ""
                }`}
              >
                <Link
                  to="/publish-an-article"
                  className="nav-link"
                  onClick={freedFramework}
                >
                  Publish an article
                </Link>
              </li> */}
              <li
                className="nav-item dropdown"
                onClick={(e) => removeIdLocalStorage(e)}
              >
                {/* <li
                className={`nav-item dropdown ${
                  splitLocation[1] === "glossary" ||
                  splitLocation[1] === "all-references" ||
                  splitLocation[1] === "external-videos"
                    ? "active"
                    : ""
                }`}
              > */}
                <a
                  // className="nav-link dropdown-toggle active"
                  className={`nav-link dropdown-toggle resources-link ${splitLocation[1] === "glossary" ||
                      splitLocation[1] === "all-references" ||
                      splitLocation[1] === "external-videos"
                      ? "active"
                      : ""
                    }`}
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Resources
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {/* <a className="dropdown-item" href="#"> */}
                  <Link to="/glossary" className="dropdown-item nav-link">
                    Glossary
                  </Link>
                  {/* </a> */}

                  {/* <a className="dropdown-item" href="/all-references"> */}
                  <Link to="/all-references" className="dropdown-item nav-link">
                    All References
                  </Link>

                  <Link
                    to="/external-videos"
                    className="dropdown-item nav-link"
                  >
                    External Videos
                  </Link>
                  {/* </a> */}
                </div>
              </li>

              <li
                className="nav-item dropdown"
                onClick={(e) => removeIdLocalStorage(e)}
              >
                {/* <li
                className={`nav-item dropdown ${
                  splitLocation[1] === "glossary" ||
                  splitLocation[1] === "all-references" ||
                  splitLocation[1] === "external-videos"
                    ? "active"
                    : ""
                }`}
              > */}
                <a
                  // className="nav-link dropdown-toggle active"
                  className={`nav-link dropdown-toggle resources-link ${splitLocation[1] === "about-us" ? "active" : ""
                    }`}
                  href="#"
                  id="navbarAboutDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarAboutDropdown"
                >
                  <Link to="/about-us" className="dropdown-item nav-link">
                    Creator of freeD
                  </Link>
                </div>
              </li>

              {/* <li
                className={`nav-item ${
                  splitLocation[1] === "about-us" ? "active" : ""
                }`}
              >
                <Link to="/about-us" className="nav-link">
                  About
                </Link>
              </li> */}

              <li
                className={`nav-item ${splitLocation[1] === "contact-us" ? "active" : ""
                  }`}
              >
                <Link to="/contact-us" className="nav-link">
                  Contact Us
                </Link>
              </li>
              <li className="desktop-hide">
                {!readCookie("loginToken") ? (
                  <Link to="/register" className="btn btn-default register-btn-mw">
                    Register
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li className="desktop-hide">
                {!readCookie("loginToken") ? (
                  <Link to="/login" className="btn btn-secondary  login-btn-mw">
                    Log in
                  </Link>
                ) : (
                  ""
                )}
              </li>

              {/* <li className="nav-item">
                <a className="nav-link" data-toggle="modal" href="#myModal">
                  Log in
                </a>
              </li>
             */}
              {/* <li>
                <input
                  className="header-search"
                  type="text"
                  id="myInput"
                  placeholder="Search"
                  title="Type"
                />
              </li> */}

            </ul>
          </div>
          <div className="header-btn-cover d-flex   ms-md-auto">
            <span className="mobile-hide d-flex">


              {!readCookie("loginToken") ? (
                <Link to="/register" className="btn btn-default register-btn-mw">
                  Register
                </Link>
              ) : (
                ""
              )}
              {!readCookie("loginToken") ? (
                <Link to="/login" className="btn btn-secondary ms-3 login-btn-mw">
                  Log in
                </Link>
              ) : (
                ""
              )}
            </span>
            {/*(
                  <button
                    className="menu-icon-btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {splitLocation[1] === "detail-page" ||
                    splitLocation[1] === "reset-password" ? (
                      <img src="../assets/images/burger.svg" />
                    ) : (
                      <img src="assets/images/burger.svg" />
                    )}

                    
                  </button>
                )*/}
            <div className="menu-icon dropdown">
              {!readCookie("loginToken") ?
                ''
                : (
                  <img
                    className="menu-icon-btn img-circle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    src={readCookie("loggedUserImage")}
                  />
                )}
                {
                  readCookie("loginToken") ? 
                  <div
                className="dropdown-menu right-dropdown"
                aria-labelledby="dropdownMenuButton"
              >

                {readCookie("loginToken") ? (
                  <Link
                    to="/edit-profile"
                    className="dropdown-item nav-link "
                  >
                    Edit Profile
                  </Link>
                ) : (
                  ""
                )}
                {readCookie("loginToken") ? (
                  <Link
                    to={{
                      pathname: "/my-article",
                      state: {
                        view_all: "clicked",
                        title_name: "My Articles",
                        page_path: "home_page",
                      },
                    }}
                    className="dropdown-item nav-link "
                  >
                    My Saved
                  </Link>
                ) : (
                  ""
                )}

                {readCookie("loginToken") ? (
                  <div
                    className="dropdown-item nav-link logout-style"
                    onClick={(e) => logoutHandlerCalled(e)}
                  >
                    <span style={{ cursor: "pointer" }}>Logout</span>
                  </div>
                ) : (
                  ""
                )}
              </div> :""
                }
              
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

        </nav>
      </div>
    </header>
  );
};
export default Header;                 
