import axios from "axios";
import { apiUrl } from "./helper";

const api = axios.create({
  baseURL: apiUrl,
});

export const customApi = axios.create({
  baseURL: apiUrl.split("/web").join(""),
});

export default api;
