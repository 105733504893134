import validator from "validator";

function contactUsValidation(data) {
  let errors = {};
  let specials = /[*|\":<>[\]{}`\\()';@&$]/;
  let dash = '"-"';
  console.log("data", data);

  if (validator.isEmpty(data.name)) errors.name = "Please enter name";
  else if (!/^[a-zA-Z ]*$/.test(data.name)) {
    errors.name = "Name should contain only alphabets.";
  } else if (data.name.length > 50) {
    errors.name = "Name must not exceed 50 characters.";
  }

  // if (validator.isEmpty(data.feedback)) errors.feedback = "Please select feedback";

  // if(!data.feedbackType) errors.feedbackType = "Please select the feedback type"

  // if (data.feedback !== undefined) {
  //   if (validator.isEmpty(data.feedback)) {
  //     errors.feedback = "Please select feedback";
  //   }
  // }
  
  if (data.feedbackType !== undefined) {
    if (!data.feedbackType) {
      errors.feedbackType = "Please select the feedback type";
    }
  }

  if (validator.isEmpty(data.email) || !data.email)
    errors.email = "Please enter email";
  else if (!/\S+@\S+\.\S+/.test(data.email)) {
    errors.email = "Email address is invalid";
  } else if (!/^.{1,50}$/.test(data.email)) {
    errors.email = "Email must not exceed 50 characters.";
  }
  if (validator.isEmpty(data.subject)) errors.subject = "Please enter subject";
  else if (data.subject.length > 50) {
    errors.subject = "Subject must not exceed 50 characters.";
  }
  // /^[a-z\d\-_\s]+$/i

  // /^[a-z0-9]+$/i
  if (validator.isEmpty("" + data.categoryValue))
    errors.categoryValue = "Please select category";

  // if (data.phone && !/^[123456789]\d{9}$/.test(data.phone))
  //   errors.phone = "Please enter valid mobile number of 10 digit.";

  // if(data.phone && specials.test(data.phone))
  // errors.phone = "Please enter valid mobile number";
  if (data.phone && !/^[0-9]{6,15}$/.test(data.phone))
    errors.phone = `Mobile Number must be a valid number of minimum 6 digit and maximum 15 digit.`;
  console.log("dataphone", data.phone.length);

  // ^[0-9]{10}$
  // \d{3}[-\.]\d{3}[-\.]\d{4}

  if (validator.isEmpty(data.message)) errors.message = "Please enter message";
  // else if (!/^[a-zA-Z ]{1,900}$/.test(data.message)) {
  //   errors.message = "Message should contain only alphabets and must not exceed 900 characters.";
  // }
  else if (data.message.length > 200) {
    errors.message = "Message must not exceed 200 characters.";
  }

  return { errors, isValid: Object.keys(errors).length <= 0 };
}
export default contactUsValidation;
