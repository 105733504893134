import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import API from "../../utils/api";
const Footer = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [configData, setConfigData] = useState([]);

  useEffect(() => {
    API.get("config/1")
      .then(({ data }) => {
        setConfigData(data.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <footer className="footer-bg">
      <div className="footer-top">
        <div className="container text-align-terms">
          <div className="row">
            <div className="col-md-12 col-lg-4 mobile-text-center">
              <a className="" href="#">
                {splitLocation[1] === "detail-page" ||
                splitLocation[1] === "reset-password" ? (
                  <img src="../assets/images/footer-white-bg.svg" />
                ) : (
                  <img src="assets/images/footer-white-bg.svg" />
                )}
                {/* <img src="assets/images/home-new-footer-logo.svg" /> */}
              </a>
            </div>
            <div className="col-md-12 col-lg-8">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="footer-label fw-semibold d-flex"><p className="mb-0 me-2 text-uppercase"> Contact </p>  freeD</div>
                  <ul className="new-footer-list">
                    <li className="new-footer-list-icon fs-16 text-white opacity-50">
                      <span>
                        {splitLocation[1] === "detail-page" ||
                        splitLocation[1] === "reset-password" ? (
                          <img src="../assets/images/footer-icon1.svg" />
                        ) : (
                          <img src="assets/images/footer-icon1.svg" />
                        )}
                      </span>
                      {configData
                        ? configData.number
                        : "Please email us to request a callback"}
                    </li>
                    <li className="new-footer-list-icon fs-16 ">
                      <a className="text-white opacity-50" href={`mailto:${configData.email}`}>
                        <span>
                          {splitLocation[1] === "detail-page" ||
                          splitLocation[1] === "reset-password" ? (
                            <img src="../assets/images/footer-icon2.svg" />
                          ) : (
                            <img src="assets/images/footer-icon2.svg" />
                          )}
                        </span>
                        {configData
                          ? configData.email
                          : " Info@freedperception.com"}
                      </a>
                    </li>
                    <li className="new-footer-list-icon fs-16 text-white opacity-50">
                      <span>
                        {splitLocation[1] === "detail-page" ||
                        splitLocation[1] === "reset-password" ? (
                          <img src="../assets/images/footer-icon3.svg" />
                        ) : (
                          <img src="assets/images/footer-icon3.svg" />
                        )}
                        {/* <img src="assets/images/footer-icon3.svg" /> */}
                      </span>
                      {configData
                        ? configData.office_address
                        : "Mountain View, CA 94043 - USA"}
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="footer-label fw-semibold text-uppercase">Quick Links</div>
                  <ul className="new-footer-list">
                    {/* <li>
                      <a href="">freeD Framework</a>
                    </li>
                    <li>
                      <a href="">Resources</a>
                    </li> */}
                    <li className="fs-16 ">
                      <Link className="text-white opacity-50" to="/about-us">About</Link>
                    </li>
                    <li className="fs-16 ">
                      <Link className="text-white opacity-50" to="/contact-us">Contact Us</Link>
                    </li>
                    <li className="fs-16 ">
                      <Link className="text-white opacity-50"
                        to="/terms-condition"
                        // className="dropdown-item nav-link"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li className="fs-16 ">
                      <Link className="text-white opacity-50"
                        to="/privacy-policy"
                        // className="dropdown-item nav-link"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-3">
                  <div className="footer-label fw-semibold text-uppercase">Social Media</div>
                  <ul className="new-footer-list">
                    <li className="new-footer-list-icon fs-16 text-white opacity-50">
                      <span>
                        {splitLocation[1] === "detail-page" ||
                        splitLocation[1] === "reset-password" ? (
                          <img src="../assets/images/footer-facebook.svg" />
                        ) : (
                          <img src="assets/images/footer-facebook.svg" />
                        )}
                      </span>
                      <div className="tooltip-facebook">
                        Facebook
                        <span className="tooltiptext">Coming soon</span>
                      </div>
                    </li>
                    <li className="new-footer-list-icon fs-16 text-white opacity-50">
                      <span className="footer-linkdin-icon">
                        {splitLocation[1] === "detail-page" ||
                        splitLocation[1] === "reset-password" ? (
                          <img src="../assets/images/footer-linkdin.svg" />
                        ) : (
                          <img src="assets/images/footer-linkdin.svg" />
                        )}
                      </span>
                      <div className="tooltip-facebook">
                        LinkedIn
                        <span className="tooltiptext">Coming soon</span>
                      </div>
                    </li>
                    {/* <li className="new-footer-list-icon">
                      <span>
                        {splitLocation[1] === "detail-page" ||
                        splitLocation[1] === "reset-password" ? (
                          <img src="../assets/images/footer-twitter.svg" />
                        ) : (
                          <img src="assets/images/footer-twitter.svg" />
                        )}
                      </span>{" "}
                      <div className="tooltip-facebook">
                        Twitter
                        <span className="tooltiptext">Coming soon</span>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-bg p-4 ">
        <div className="container text-white opacity-50 text-center fs-16 fw-semibold border-top pt-4">Copyright © 2024. All Rights Reserved</div>
      </div>
    </footer>
  );
};
export default Footer;
