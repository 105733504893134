import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

const CookieSettingsModal = ({acceptCookie, show, setShow }) => {


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isStatisticsAccepted, setIsStatisticsAccepted] = useState(true)

  // document.cookie = "SelectedId=" + id;

  const handleCookieSave = () => {
    // document.cookie = "StatisticsCookie=" + isStatisticsAccepted;

    acceptCookie(isStatisticsAccepted)
  }



  return (
    <>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cookie Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body className='border-0 '>
          <div className="row border-bottom pt-2">
            <div className="col"><p className='h6 fw-bold'>Necessary</p></div>
            <div className="col-auto">
              <Form.Check
                checked
                disabled
                type="switch"
                id="custom-switch"
                className='custom-switch'
              /></div>
            <div className="col-12">
              <p className='text-justify pt-2'>Necessary cookies are essential to provide you with our Services. It enables basic functions like page navigation and access to secure areas of the Services. You cannot opt-out of this.</p>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col"><p className='h6 fw-bold'>Statistics and performance</p></div>
            <div className="col-auto">
              <Form.Check
                type="switch"
                id="custom-switch"
                className="custom-switch"
                checked={isStatisticsAccepted}
                onChange={(e) => {
                  setIsStatisticsAccepted(e.target.checked)
                }}
              /></div>
            <div className="col-12">
              <p className='text-justify pt-2'>Statistics and performance cookies help us understand how visitors interact with our website by collecting and reporting usage information. This data allows us to improve our site and provide a better user experience. We recommend opting-in.</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-sm btn-primary fw-normal' onClick={handleCookieSave}>
            Save and accept
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CookieSettingsModal