import React, { useEffect, useState } from "react";
import "./App.css";
import Routes from "./Routes";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import 'regenerator-runtime/runtime';
import ReactGA from 'react-ga';
import { ToastContainer } from "react-toastify";
import CookieSettingsModal from "./components/Modal/CookieSettingsModal"
import { Link } from "react-router-dom";
import { delete_cookie, readCookie } from "./cookie-function";
import api from "./utils/api";
// ReactGA.initialize('UA-300168360-1');

function App() {

  const [statsCookie, setStatsCookie] = useState(false)
  const [isConsentAccepted, setIsConsentAccepted] = useState(false)

  // LOAD GOOGLE TAG MANAGER
  const loadGTM = () => {
    if (!document.getElementById('gtm-script')) {
      const script = document.createElement('script');
      script.id = 'gtm-script';
      script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-300168360-1'; // Replace with your GTM Container ID
      script.async = true;
      document.head.appendChild(script);


    }
  };

  const getUserProfile = () => {
    const UserId = readCookie("UserId")
    api.get(`user/${UserId}`)
      .then(({ data }) => {
        if (data.meta.code !== 1) {
          // LOGOUT
          delete_cookie("loginToken");
          delete_cookie("loggedUserImage");
          delete_cookie("EditUserImage");
          delete_cookie("UserId");
        }
      })
      .catch((err) => {
        console.log({ "ERROR": err?.message })
      });
  };

  useEffect(() => {
    // IF LOGIN TOKEN AVAILABLE THEN GET USER INFO
    if (readCookie("loginToken")) {
      getUserProfile()
    }
    const statsCookie = getCookieConsentValue('StatisticsCookie');
    if (statsCookie === true) {
      loadGTM();
      ReactGA.initialize('UA-300168360-1');
      setStatsCookie(true)
    }
  }, []);




  const [openCookieSetting, setOpenCookieSettings] = useState(false)

  const handleAccept = (statisticsCookie) => {


    // Close the cookie consent bar
    // if (!getCookieConsentValue()) {
    //   document.getElementById('rcc-confirm-button').click();
    // }
    // IF STATS COOKIE ACCEPTED THEN ONLY GOOGLE ANALYTICS WILL BE USED
    if (statisticsCookie && statisticsCookie === true) {
      console.log("calling this")
      loadGTM();
      ReactGA.initialize('UA-300168360-1');
      setStatsCookie(true)
    }

    if (readCookie('myAwesomeCookieName2') !== undefined) {
      document.cookie = "myAwesomeCookieName2=; Max-Age=-99999999; path=/";
    }

    // Set the new value for myAwesomeCookieName2
    document.cookie = "myAwesomeCookieName2=" + true + "; path=/";
    // Set the StatisticsCookie
    document.cookie = "StatisticsCookie=" + (statisticsCookie ? "true" : "false") + "; path=/";

    // document.cookie = "StatisticsCookie=" + statisticsCookie;
    // document.cookie = "myAwesomeCookieName2=" + true
    setOpenCookieSettings(false)

    setIsConsentAccepted(true)
  }

  return (
    <div className="App body-mid-backgraund">
      <div className="wrapper">
        <Header statsCookie={statsCookie} />
        <Routes />
      </div>

      <Footer />
      {/* <CookieConsent
        location="bottom"
        buttonText="Accept!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={999}
        overlay
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent> */}
      {
        !isConsentAccepted ? <CookieConsent


          id="cookie-consent-container"
          location="bottom"
          buttonText="Accept!!"
          enableDeclineButton
          declineButtonText="Decline"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B", }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={999}
          containerClasses="w-100 cookie-consent-container"
          // overlay
          onAccept={() => handleAccept(true)}
          flipButtons
        >
          We use cookies and tracking technologies to enhance your browsing experience, personalize content, analyze traffic, and understand our audience. By clicking 'Accept,' you consent to our use of cookies. If you prefer not to allow cookies, click 'Decline.' You can also adjust your preferences through your browser settings at any time. For more details, please refer to our <Link to="/privacy-policy">Privacy Policy</Link>.
          <div className=" mb-0">
            <p onClick={() => setOpenCookieSettings(true)} className="cursor-pointer text-decoration-underline text-white fw-bold text-underline mb-0 mt-4 text-small">Cookie Settings</p>
          </div>
        </CookieConsent> : ""
      }

      {/* COOKIE SETTINGS */}
      <CookieSettingsModal acceptCookie={handleAccept} show={openCookieSetting} setShow={setOpenCookieSettings} />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
